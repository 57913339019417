<template>
  <div>
    <div class="text-center">
      <h3 class="firstcapital text-capitalize popupRemappMake">Select Your Manufacturer (Make)</h3>
    </div>
    <div class="d-flex justify-content-center">
      <select v-model="Vehicle_Make">
        <option v-for="(item) in fetched_data.manufacturer" :value="item" :key="item" class="popupRemappInput">
          {{ item }}
        </option>
      </select>
    </div>
    <div v-if="fetched_data.model.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Your Model</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="Vehicle_Model">
          <option v-for="(item) in fetched_data.model" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="fetched_data.production_from.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Year</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="production_from">
          <option v-for="(item) in fetched_data.production_from" :value="item.v" :key="item" class="popupRemappInput">
            {{ item.l }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fetched_data: [],
      form_data: {},
      Vehicle_Make: '',
      Vehicle_Model: '',
      production_from: ''
    }
  },
  methods: {
    sync_vue_with_global() {
      this.form_data.Vehicle_Make = this.Vehicle_Make;
      this.form_data.Vehicle_Model = this.Vehicle_Model;
      this.form_data.production_from = this.production_from;
      form_data = this.form_data;
      fetched_data = get_ghost_compatible_data();
      this.fetched_data = fetched_data;
    },
    sync_global_with_vue() {
      this.form_data = form_data;
      this.fetched_data = fetched_data;
      this.Vehicle_Make = this.form_data.Vehicle_Make;
      this.Vehicle_Model = this.form_data.Vehicle_Model;
      this.production_from = this.form_data.production_from;
    }
  },
  created() {
    this.sync_global_with_vue();
  },
  watch: {
    Vehicle_Make: function (val) {
      this.Vehicle_Make = val;
      this.sync_vue_with_global();
    },
    Vehicle_Model: function (val) {
      this.Vehicle_Model = val;
      this.sync_vue_with_global();
    },
    production_from: function (val) {
      this.production_from = val;
      this.sync_vue_with_global();
    },
    model_short_detail: function (val) {
      this.model_short_detail = val;
      this.sync_vue_with_global();
    },
    model_detail: function (val) {
      this.model_detail = val;
      this.sync_vue_with_global();
    }
  }
}
</script>
