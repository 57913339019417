<template>
  <div>
    <div class="text-center">
      <h3 class="firstcapital text-capitalize popupRemappMake">Select Your Manufacturer (Make)
      </h3>
    </div>
    <div class="d-flex justify-content-center">
      <select v-model="Vehicle_Make" @change="option_selected($event,'Vehicle_Make')">
        <option v-for="(item) in fetched_data.manufacturer" :value="item" :key="item" class="popupRemappInput">
          {{ item }}
        </option>
      </select>
    </div>
    <div v-if="fetched_data.model.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Your Model</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="Vehicle_Model" @change="option_selected($event,'Vehicle_Model')">
          <option v-for="(item) in fetched_data.model" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
        <span
          style="padding-top: 1%;padding-left: 1%;"
          v-if="Vehicle_Model"
          v-on:click="option_selected('', 'Vehicle_Model')">
          <i class="fa fa-trash"></i>
        </span>
      </div>
    </div>
    <div v-if="fetched_data.model_alia.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappMake">or Select</h3>
      </div>
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Your Model Alia</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="Vehicle_Model_Alia" @change="option_selected($event,'Vehicle_Model_Alia')">
          <option v-for="(item) in fetched_data.model_alia" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
        <span
          style="padding-top: 1%;padding-left: 1%;"
          v-if="Vehicle_Model_Alia"
          v-on:click="option_selected('', 'Vehicle_Model_Alia')">
          <i class="fa fa-trash"></i>
        </span>
      </div>
    </div>
    <div v-if="fetched_data.fuel.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Fuel</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="fuel" @change="option_selected($event,'fuel')">
          <option v-for="(item) in fetched_data.fuel" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="fetched_data.model_short_detail.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Model Generation</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="model_short_detail" @change="option_selected($event,'model_short_detail')">
          <option v-for="(item) in fetched_data.model_short_detail" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="fetched_data.model_detail.length">
      <div class="text-center">
        <h3 class="firstcapital text-capitalize popupRemappLabels">Variant (Optional)</h3>
      </div>
      <div class="d-flex justify-content-center">
        <select v-model="model_detail" @change="option_selected($event,'model_detail')">
          <option v-for="(item) in fetched_data.model_detail" :value="item" :key="item" class="popupRemappInput">
            {{ item }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fetched_data: [],
      form_data: {},
      Vehicle_Make: '',
      Vehicle_Model: '',
      Vehicle_Model_Alia: '',
      fuel: '',
      model_detail: '',
      model_short_detail: '',
    }
  },
  methods: {
    sync_vue_with_global() {
      this.form_data.Vehicle_Make = this.Vehicle_Make;
      this.form_data.Vehicle_Model = this.Vehicle_Model;
      this.form_data.Vehicle_Model_Alia = this.Vehicle_Model_Alia;
      this.form_data.fuel = this.fuel;
      this.form_data.model_short_detail = this.model_short_detail;
      this.form_data.model_detail = this.model_detail;
      form_data = this.form_data;
      fetched_data = get_engine_remapping_data();
      this.fetched_data = fetched_data;
    },
    sync_global_with_vue() {
      this.form_data = form_data;
      this.fetched_data = fetched_data;
      this.Vehicle_Make = this.form_data.Vehicle_Make;
      this.Vehicle_Model = this.form_data.Vehicle_Model;
      this.Vehicle_Model_Alia = this.form_data.Vehicle_Model_Alia;
      this.fuel = this.form_data.fuel;
      this.model_short_detail = this.form_data.model_short_detail;
      this.model_detail = this.form_data.model_detail;
    },
    option_selected(event, variableName) {
      if (event.target !== undefined) {
        this[variableName] = event.target.value;
      } else {
        this[variableName] = event;
      }
      this.sync_vue_with_global();
    }
  },
  created() {
    this.sync_global_with_vue();
  }
}
</script>
