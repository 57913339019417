import AgentsGoogleMap from "./components/AgentsGoogleMap";

require('./api_search');

import Vue from 'vue';

import EngineRemappingForm from './components/EngineRemappingForm';
import GhostCompatibleForm from './components/GhostCompatibleForm';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDJAYHzlPCDKvsmqKCToo-o952YqHK_KPQ',
    libraries: 'places,drawing,visualization'
  }
});

Vue.component('engine-remapping-form', EngineRemappingForm);
Vue.component('ghost-compatible-form', GhostCompatibleForm);
Vue.component('agents-google-map', AgentsGoogleMap);

let app = new Vue({
  el: '#app-content'
});

