<template>
  <div>
    <GmapMap
      ref="mapRef"
      :options="{
       zoomControl: false,
       fullScreenControl: false
     }"
      :center="center_data"
      :zoom="6"
      map-type-id="terrain"
      style="width: 100%; height: 400px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :icon="m.icon"
        @click="agentPopup(m)"
      />
    </GmapMap>
  </div>
</template>

<script>

export default {
  props: ['markers', 'center_data'],
  data() {
    return {}
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      var bounds = new google.maps.LatLngBounds();
      this.markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
      });
      map.fitBounds(bounds);
    })
  },
  methods: {
    toggleInfoWindow(context) {
      this.infoWIndowContext = context
      this.showInfo = true
    },
    agentPopup(m) {
      popup(m.link);
    }
  }
}
</script>
